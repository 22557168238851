<template>
  <form v-if="isTestLogin">
    <div v-if="isSocialLoginFlow">Looks like you have duplicated accounts under the same email. Enter your password for Burpple to link them.</div>

    <form-input
      name="email"
      type="email"
      rules="required"
      v-model="user.email"
      labelName="Email Address"
      :labelImgSrc="require('@/assets/images/icon-email')"
    ></form-input>

    <form-input
      name="password"
      type="password"
      rules="required"
      v-model="user.password"
      labelName="Password"
      :labelImgSrc="require('@/assets/images/icon-password')"
      :labelLink="{ title: 'Forgot?', event: toPasswordReset }"
    ></form-input>

    <button
      @click.prevent="onSubmit()"
      :disabled="isSubmitting"
      class="btn btn-block btn-beyond-green"
      :class="{ 'loading': isSubmitting }"
    >log in</button>

    <div class="error-msg">{{ error_msg }}</div>
  </form>
</template>

<script>
import FormInput from '@/shared/components/form_input'

export default {
  computed: {
    isTestLogin() {
      return window.location.search.includes('test_login=true');
    },
  },
  components: {
    FormInput
  },
  props: {
    currentProperties: {
      type: Object
    },
    partner: {
      type: String
    }
  },
  data: function() {
    return ({
      user: {
        email: '',
        password: '',
        fb_token: '',
        google_id_token: ''
      },
      error_msg: '',
      isSubmitting: false,
      isSocialLoginFlow: false,
      logInType: 'email'
    })
  },
  methods: {
    onSubmit: function () {
      this.error_msg = ''

      // eslint-disable-next-line no-undef
      amplitudeLogEvent(BURPPLE_AMPLITUDE.start_sign_in, {
        'type': this.logInType
      })

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isSubmitting = true
          this.onVerify()
          return
        }
      })
    },
    onVerify: function () {
      // using recaptcha v3
      this.$recaptcha('login').then((token) => {

        // to axios post api
        this.$http.post('/api/users/sign_in', {
          user: this.user,
          partner: this.partner,
          'g-recaptcha-response': token
        })
          .then(response => {
            // eslint-disable-next-line no-undef
            amplitudeLogEvent(BURPPLE_AMPLITUDE.complete_sign_in, {
              'type': this.logInType
            })
            if (this.logInType === 'facebook') {
              fbq('trackCustom', 'SessionCreate');
            }
            if (response.data.meta.code == 301) {
              location.replace(response.data.meta.redirect_url)
            } else {
              // if sign_in success, reload current page
              location.reload(true)
            }
          })
          .catch(error => {
            if (error.response.status == 422 && error.response.data) {
              let dataObject = error.response.data
              for(let index in dataObject) {
                this.errors.add({
                  field: index,
                  msg: dataObject[index][0]
                })
              }
            } else {
              this.error_msg = error.response.data.error.message
            }
            this.isSubmitting = false

            // eslint-disable-next-line no-undef
            amplitudeLogEvent('popup login app error', {
              'action': 'login',
              'msg': this.error_msg
            })
          })
      })
    },
    updateDataUser: function () {
      if (this.currentProperties) {
        if (this.currentProperties.fb_token !== '' && this.currentProperties.fb_token !== null) {
          this.user.fb_token = this.currentProperties.fb_token
          this.logInType = 'facebook'
        } else if (this.currentProperties.google_id_token !== '' && this.currentProperties.google_id_token !== null) {
          this.user.google_id_token = this.currentProperties.google_id_token
          this.logInType = 'google'
        }
        this.user.email = this.currentProperties.email
        this.isSocialLoginFlow = true
      }
    },
    toPasswordReset: function () {
      this.$emit('swapComponent', 'reset-password')
    },
  },
  beforeMount() {
    this.updateDataUser()
  },
  watch: {
    currentProperties: function () {
      this.updateDataUser()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100%;
}

.error-msg {
  color: #ff2626;
  text-align: center;
  margin-top: 10px;
}
</style>
