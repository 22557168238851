<template>
  <div :class="{ 'popup-backdrop': overlayStyle }">
    <div :class="{ 'popup popup-vuejs': overlayStyle }">

      <div
        class="popup-dialog"
        :class="{ 'full-page': fullPageStyle }"
      >
        <div class="popup-content">

          <div v-if="showTripAdvisor" class="ta-banner">
            Thank you for supporting #LoveYourLocalSG!
          </div>
          <div v-if="showGomo" class="gomo-banner">
            Welcome, GOMO Fam!
          </div>
          <div v-if="showSingtelMusic" class="gomo-banner">
            Thank you for choosing Singtel Music!
          </div>

          <div
            v-if="showHeader"
            class="popup-header"
          >
            <slot name="header"></slot>

            <div
              v-if="dismissible"
              @click="closePopup()"
              class="popup-header__close"
            >
              <img
                :src="require('@/assets/images/icon-popup-close')"
                alt="icon popup close"
              >
            </div>
          </div>

          <div class="popup-body">
            <slot></slot>
          </div>

          <div
            v-if="showFooter"
            class="popup-footer popup-footer-border-top popup-footer-center"
          >
            <slot name="footer"></slot>
          </div>

        </div>
      </div>

      <div v-if="showPopupInPopup"
        class="popup-backdrop-in-popup">
        <div class="popup-dialog-in-popup">
          <slot name="popup-in-popup"></slot>
        </div>
      </div>

      <div v-if="showLoadingInPopup"
        class="popup-backdrop-in-popup">
        <div class="loading-indicator"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    dismissible: {
      type: Boolean,
      required: true
    },
    overlayStyle: {
      type: Boolean,
      required: true,
      default: true
    },
    fullPageStyle: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showPopupInPopup: {
      type: Boolean,
      default: false
    },
    showLoadingInPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showTripAdvisor: function() {
      const urlParams = new URLSearchParams(window.location.search);
      const partner = urlParams.get('partner');
      return partner == "tripadvisor"
    },
    showGomo: function() {
      const urlParams = new URLSearchParams(window.location.search);
      const partner = urlParams.get('partner');
      return partner == "gomo"
    },
    showSingtelMusic: function() {
      const urlParams = new URLSearchParams(window.location.search);
      const partner = urlParams.get('partner');
      return partner == "singtelmusic"
    },
  },
  methods: {
    closePopup: function () {
      this.$emit('closePopup')
    },
  }
}
</script>

<style lang="scss" scoped>
.popup-backdrop {
  display: block;
  z-index: 5060;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.popup.popup-vuejs {
  z-index: 5061;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.popup-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));

  // @include media-breakpoint-up(sm)
  @media (min-width: 576px) {
    min-height: calc(100% - (1.75rem * 2));
    max-width: 375px;
    margin: 1.75rem auto;
  }
}

.popup-dialog.full-page {
  margin: 0 auto;
  align-items: flex-start;
}

.popup-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
}

.ta-banner {
  background-color: #4ca771;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  border-radius: 4px 4px 0 0;
}

.gomo-banner {
  background-color: #e31e24;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  border-radius: 4px 4px 0 0;
}

.popup-header {
  display: flex;
  padding: 20px 20px 0px;
  flex-direction: column;
}

.popup-header__title {
  font-size: 18.5px;
  font-weight: bold;
  line-height: 1.35;
  color: #2b3f47;
  width: 100%;

  &.fz-28 {
    font-size: 28px;
  }
}

.popup-header__description {
  display: flex;
  font-size: 24px;
  font-weight: 800;
  color: #2b3f47;
  line-height: 28px;

  & span:not(:first-child)::before {
    content: '\2022';
    margin: 0 0.5rem;
    font-size: 1.5rem;
  }
}

.popup-header__subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #555555;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.popup-header__close {
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.popup-body {
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > div {
    padding: 0 0.6rem;
  }

  &__create-account {
    font-weight: 600;
  }

  &__info {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  // @include media-breakpoint-up(sm)
  @media (min-width: 576px) {
    .btn {
      font-size: 16px;
    }
  }
}

.popup-footer {
  padding: 20px;

  a {
    font-weight: bold;
  }

  &.popup-footer-center {
    text-align: center;
  }

  &.popup-footer-border-top {
    border-top: solid 4px #eeeeee;
  }
}

.popup-body__or {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;

  .popup-body__or-span {
    display: inline-block;
    z-index: 1;
    position: relative;
    padding: 0 10px;
    color: #ababab;
    background-color: #fff;
  }

  hr {
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    border-color: #dadada;
  }
}

.popup-backdrop-in-popup {
  display: block;
  z-index: 5060;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .popup-dialog-in-popup {
    margin: 10px;
    background-color: #fff;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    max-width: 375px;
    width: 100%;

    .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.35;
      color: #2b3f47;
      padding: 20px 10px;
    }

    .actions {
      display: flex;
      padding: 20px 10px;
      align-items: center;
      justify-content: flex-end;

      .btn-cancel {
        font-size: 14px;
        cursor: pointer;
        color: #777777;
      }

      .btn-burpple-pink {
        font-size: 14px;
        cursor: pointer;
        border-radius: 3px;
        background-color: #ea246e;
        color: #fff;
        margin-left: 20px;
      }
    }
  }

  .loading-indicator {
    color: transparent !important;
    min-height: 2rem;
    pointer-events: none;
    position: relative;

    &::after {
      animation: loading 500ms infinite linear;
      border: .2rem solid #fff;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1.6rem;
      left: 50%;
      margin-left: -.8rem;
      margin-top: -.8rem;
      position: absolute;
      top: 50%;
      width: 1.6rem;
      z-index: 1;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
