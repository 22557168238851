<template>
  <div class="reset-body">

    <img :src="require('@/assets/images/icon-ok-circle')" alt="icon ok">
    <p class="desc">If you have an account with us, you'll receive an email to reset your password.</p>

    <a href="#" @click.prevent="toLogIn" class="btn btn-block btn-beyond-green">BACK TO LOGIN</a>

  </div>
</template>

<script>
export default {
  methods: {
    toLogIn: function () {
      this.$emit('swapComponent', 'log-in')
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-body {
  text-align: center;
  display: flex;
  flex-direction: column;

  .desc {
    padding: 20px;
  }
}
</style>
