<!-- <template>
  <div>
    <button @click.prevent="toSignUp()" class="btn btn-block btn-beyond-green">Sign up with email</button>
  </div>
</template> -->

<script>
export default {
  methods: {
    toSignUp: function () {
      this.$emit('swapComponent', 'sign-up')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100%;
}
</style>
