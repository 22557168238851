<template>
  <div>
    <!-- <a
      href="#"
      @click.prevent="logInWithFacebook"
      class="btn btn-block btn--facebook"
      v-bind:class="{ loading: isLoadingFacebook, disabled: isLoadingFacebook }"
    >Continue with facebook</a> -->

    <!-- <g-signin-button
      :params="googleSignInParams"
      @success="logInWithGoogle"
      class="btn btn-block btn--google"
    >Continue with Google</g-signin-button> -->

    <a
      href="#"
      @click.prevent="logInWithTridentity"
      class="btn btn-block btn--tridentity"
      v-bind:class="{ loading: isLoadingTridentity, disabled: isLoadingTridentity }"
    >Login with <span class="tridentity-logo"></span>Tridentity</a>

    <div v-if="error_msg" class="error-msg">{{ error_msg }}</div>
  </div>
</template>

<script>
export default {
  props: {
    googleClientId: {
      type: String,
      required: true
    }
  },
  data: function() {
    return ({
      user: {
        fb_token: '',
        google_id_token: ''
      },
      error_msg: '',
      googleSignInParams: {
        client_id: this.googleClientId
      },
      isLoadingFacebook: false,
      isLoadingTridentity: false,
    })
  },
  methods: {
    clearLoadingFacebook: function() {
      this.isLoadingFacebook = false
    },
    setLoadingFacebook: function() {
      this.isLoadingFacebook = true
    },
    clearLoadingTridentity: function() {
      this.isLoadingTridentity = false
    },
    setLoadingTridentity: function() {
      this.isLoadingTridentity = true
    },
    logInWithTridentity: function () {
      const TRIDENTITY_FE_URL = window.TRIDENTITY_FE_URL;
      const TRIDENTITY_APP_ID = window.TRIDENTITY_APP_ID;

      const TRIDENTITY_LOGIN_PAGE = `${TRIDENTITY_FE_URL}/oauth/login?app_id=${TRIDENTITY_APP_ID}`
      
      this.setLoadingTridentity()
      // eslint-disable-next-line no-undef
      amplitudeLogEvent(BURPPLE_AMPLITUDE.start_sign_in, {
        'type': 'tridentity'
      })

      // Redirect to tridentity login page
      window.location.href = TRIDENTITY_LOGIN_PAGE
    },
    logInWithFacebook: function () {
      this.setLoadingFacebook()
      // eslint-disable-next-line no-undef
      amplitudeLogEvent(BURPPLE_AMPLITUDE.start_sign_in, {
        'type': 'facebook'
      })

      let vm = this
      /* eslint-disable-next-line no-undef */
      FB.login(function(response) {
        if (response.authResponse) {
          vm.user.fb_token = response.authResponse.accessToken
          vm.$http.post('/api/users/sign_in', { user: vm.user })
            .then(response => {
              if (response.data.to) {
                let user = response.data.user
                vm.$emit('swapComponent', response.data.to, user)
              } else {
                // eslint-disable-next-line no-undef
                amplitudeLogEvent(BURPPLE_AMPLITUDE.complete_sign_in, {
                  'type': 'facebook'
                })
                if (response.data.meta.code == 301) {
                  location.replace(response.data.meta.redirect_url)
                } else {
                  // if sign_in success, reload current page
                  location.reload(true)
                }
              }
            })
            .catch(() => {
              vm.error_msg = 'Something went wrong, please try again.'
            })
        } else {
          // user cancelled login or did not grant authorization
        }
        vm.clearLoadingFacebook()
      }, {scope: 'email'})
    },
    logInWithGoogle (googleUser) {
      // eslint-disable-next-line no-undef
      amplitudeLogEvent(BURPPLE_AMPLITUDE.start_sign_in, {
        'type': 'google'
      })

      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      let id_token = googleUser.getAuthResponse().id_token;
      if (id_token) {
        this.user.google_id_token = id_token
        this.$http.post('/api/users/sign_in', { user: this.user })
          .then(response => {
            if (response.data.to) {
              let user = response.data.user
              this.$emit('swapComponent', response.data.to, user)
            } else {
              // eslint-disable-next-line no-undef
              amplitudeLogEvent(BURPPLE_AMPLITUDE.complete_sign_in, {
                'type': 'google'
              })
              if (response.data.meta.code == 301) {
                location.replace(response.data.meta.redirect_url)
              } else {
                // if sign_in success, reload current page
                location.reload(true)
              }
            }
          })
          .catch(() => {
            this.error_msg = 'Something went wrong, please try again.'
          })
      } else {
        // user cancelled login or did not grant authorization
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.btn--facebook {
  background-color: #3b5998;
  position: relative;

  &:before {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("~@/assets/images/login-logo-fb-white");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
  }

  &:hover,
  &:focus {
    background-color: #3b5998;
    text-decoration: none;
  }
}

.btn--google {
  color: #555555;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  position: relative;

  &:before {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("~@/assets/images/login-logo-google");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
  }

  &:hover,
  &:focus {
    background-color: #ffffff;
    text-decoration: none;
  }
}

.btn--tridentity {
  border-radius: 4px;
  background: var(--Pink-400, #E5146B);
  position: relative;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  gap: 0.35rem;
  border: solid 1px var(--Pink-400, #E5146B);

  &:hover,
  &:focus {
    background-color: #ffffff;
    color: black;
    text-decoration: none;
    border: solid 1px black;

    .tridentity-logo {
      filter: brightness(50%);
    }
  }

  & .tridentity-logo {
    display: block;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/login-logo-tridentity");
    margin-top: 4px;
    right: -0.1rem;
    position: relative;
  }
}

.error-msg {
  color: #ff2626;
  text-align: center;
  margin-top: 10px;
}
</style>
