<template>
  <form>
    <div class="inline-form-group">
      <form-input
        name="first_name"
        type="text"
        rules="required"
        v-model="user.first_name"
        labelName="First Name"
        :labelImgSrc="require('@/assets/images/icon-name')"
      ></form-input>

      <form-input
        name="last_name"
        type="text"
        rules="required"
        v-model="user.last_name"
        labelName="Last Name"
        :labelImgSrc="require('@/assets/images/icon-name')"
      ></form-input>
    </div>

    <form-input
      name="email"
      type="email"
      rules="required|email"
      v-model="user.email"
      labelName="Email Address"
      :labelImgSrc="require('@/assets/images/icon-email')"
    ></form-input>

    <form-input
      name="email_confirmation"
      type="email"
      rules="required"
      onpaste="return false"
      v-model="user.email_confirmation"
      labelName="Confirm Email Address"
      :labelImgSrc="require('@/assets/images/icon-email')"
    ></form-input>

    <form-input
      v-if="!isSocialLoginFlow"
      name="password"
      type="password"
      rules="required|min:8"
      v-model="user.password"
      labelName="Password"
      labelHint="<span>(min. 8 characters)</span>"
      :labelImgSrc="require('@/assets/images/icon-password')"
    ></form-input>

    <button
      @click.prevent="onSubmit()"
      :disabled="isSubmitting"
      class="btn btn-block btn-beyond-green"
      :class="{ 'loading': isSubmitting }"
    >{{ isSocialLoginFlow? 'Confirm' : 'Sign up' }}</button>

    <div class="error-msg">{{ error_msg }}</div>
  </form>
</template>

<script>
import FormInput from '@/shared/components/form_input'

export default {
  components: {
    FormInput
  },
  props: {
    currentProperties: {
      type: Object
    },
    cityName: {
      type: String
    },
    countryName: {
      type: String
    },
    partner: {
      type: String
    }
  },
  data: function() {
    return ({
      user: {
        first_name: '',
        last_name: '',
        email: '',
        email_confirmation: '',
        password: '',
        fb_token: '',
        google_id_token: '',
        city: this.cityName,
        country: this.countryName
      },
      error_msg: '',
      isSubmitting: false,
      isSocialLoginFlow: false,
      signUpType: 'email'
    })
  },
  methods: {
    onSubmit: function () {
      this.error_msg = ''

      // eslint-disable-next-line no-undef
      amplitudeLogEvent(BURPPLE_AMPLITUDE.start_sign_up, {
        'type': this.signUpType
      })

      if (this.user.email !== this.user.email_confirmation) {
        this.errors.add({
          field: 'email_confirmation',
          msg: 'The email addresses do not match'
        })
        return
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isSubmitting = true
          this.onVerify()
          return
        }
      })
    },
    onVerify: function () {
      // using recaptcha v3
      this.$recaptcha('signup').then((token) => {

        // to axios post api
        this.$http.post('/api/users', {
          user: this.user,
          partner: this.partner,
          'g-recaptcha-response': token
        })
          .then(response => {
            // eslint-disable-next-line no-undef
            amplitudeLogEvent(BURPPLE_AMPLITUDE.complete_sign_up, {
              'type': this.signUpType,
              'new user': true
            })
            if (this.signUpType === 'facebook') {
              fbq('track', 'CompleteRegistration', {});
              fbq('trackCustom', 'SessionCreate');
            }
            if (response.data.meta.code == 301) {
              location.replace(response.data.meta.redirect_url)
            } else {
              // if sign_in success, reload current page
              location.reload(true)
            }
          })
          .catch(error => {
            if (error.response.status == 422 && error.response.data) {
              let dataObject = error.response.data
              for(var index in dataObject) {
                this.errors.add({
                  field: index,
                  msg: dataObject[index][0]
                })
              }
            } else {
              this.error_msg = error.response.data.error.message
            }
            this.isSubmitting = false

            // eslint-disable-next-line no-undef
            amplitudeLogEvent('popup login app error', {
              'action': 'signup',
              'msg': this.error_msg
            })
          })
      })
    },
    updateDataUser: function () {
      if (this.currentProperties) {
        if (this.currentProperties.fb_token !== '' && this.currentProperties.fb_token !== null && this.currentProperties.fb_token !== undefined) {
          this.user.fb_token = this.currentProperties.fb_token
          this.signUpType = 'facebook'
        } else if (this.currentProperties.google_id_token !== '' && this.currentProperties.google_id_token !== null && this.currentProperties.google_id_token !== undefined) {
          this.user.google_id_token = this.currentProperties.google_id_token
          this.signUpType = 'google'
        }
        this.user.first_name = this.currentProperties.first_name
        this.user.last_name = this.currentProperties.last_name
        this.user.email = this.currentProperties.email
        this.isSocialLoginFlow = true
      }
    }
  },
  beforeMount() {
    this.updateDataUser()
  },
  watch: {
    currentProperties: function () {
      this.updateDataUser()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100%;
}

.inline-form-group {
  display: flex;

  .form-input {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.error-msg {
  color: #ff2626;
  text-align: center;
  margin-top: 10px;
}
</style>
