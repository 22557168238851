<template>
  <div class="form-input">

    <label v-if="labelName">
      <img v-if="labelImgSrc" :src="labelImgSrc">
      {{ labelName }}
      <span v-html="labelHint"></span>

      <a
        href="#"
        v-if="labelLink"
        @click.prevent="labelLink.event">
        {{ labelLink.title }}
      </a>
    </label>

    <input
      :type="type"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      v-validate="rules"
      :data-vv-as="labelName"
      :class="inputClassObject(name)"
      @input="updateValue($event.target.value)">

    <span
      class="input-error-msg"
      v-show="errors.has(name)"
    >
      {{ errors.first(name) }}
    </span>

  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    value: {
      required: true
    },
    name: {
      type: String,
      require: true
    },
    rules: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    labelName: {
      type: String
    },
    labelImgSrc: {
      type: String
    },
    labelHint: {
      type: String
    },
    labelLink: {
      title: {
        type: String,
        require: true
      },
      event: {
        type: Function,
        require: true
      }
    },
    placeholder: {
      type: String
    }
  },
  methods: {
    updateValue(val) {
      this.$emit('input', val)
    },
    inputClassObject: function (name) {
      return {
        'input-control': true,
        'input-control-error': this.errors.has(name),
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input {
  margin-bottom: 15px;

  label {
    font-weight: 600;
    display: flex;

    img {
      margin-right: 8px;
    }

    span {
      margin-left: 5px;
      font-weight: normal;
      font-style: italic;
    }

    a {
      color: #57ba77;
      margin-left: auto;
    }
  }

  .input-control {
    height: 45px;
    padding: 10px;
    width: 100%;
    background-color: #ffffff;
    border: solid 1px #dddddd;
    border-radius: 4px;

    &::placeholder {
      color: rgba(119, 119, 119, 0.5);
    }
  }

  .input-control-error {
    border-color: #ff2626;
  }

  .input-error-msg {
    color: #ff2626;
    font-size: 13px;
  }
}
</style>
