import Vue from 'vue/dist/vue.esm'
import Axios from 'axios'
import VeeValidate from 'vee-validate'
import GSignInButton from 'vue-google-signin-button'
import { VueReCaptcha } from 'vue-recaptcha-v3'

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
Axios.defaults.headers.common['X-CSRF-Token'] = token
Axios.defaults.headers.common['Accept'] = 'application/json'
Vue.prototype.$http = Axios

Vue.use(VeeValidate)
Vue.use(GSignInButton)

import PopupLoginApp from '../popup_login/popup_login_app.vue'

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('popup-login-app')

  if (el.dataset.userSignedIn === 'true') return

  window.vuePopupLoginApp = new Vue({
    el,
    components: {
      PopupLoginApp
    },
    render: createElement => createElement('popup-login-app', {
      props: {
        cityName: el.dataset.cityName,
        countryName: el.dataset.countryName,
        googleClientId: el.dataset.googleClientId,
        partner: el.dataset.partner,
        initComponent: el.dataset.initComponent,
        isStaticPage: el.dataset.staticPage === 'true',
        removeSocialLogin: el.dataset.removeSocialLogin == 'true'
      }
    }),
    methods: {
      show(component, eventProperties = {}, authRequired = false) {
        this.$emit('showPopupLoginApp', component, eventProperties, authRequired)
      }
    }
  })

  Vue.use(VueReCaptcha, {
    siteKey: el.dataset.recaptchaSiteKey,
    loaderOptions: {
      autoHideBadge: true
    }
  })

  window.showLoginApp = function() {
    window.vuePopupLoginApp.show(el.dataset.initComponent, {}, true);
  }

  window.loginWithFBAccessToken = function(accessToken) {
    Axios.post('/api/users/sign_in', { user: { fb_token: accessToken } })
      .then(function(resp) {
        if (resp.data.to) {
          window.showLoginApp()
        } else {
          amplitudeLogEvent(BURPPLE_AMPLITUDE.complete_sign_in, {
            type: 'facebook'
          })
          fbq('trackCustom', 'SessionCreate');
          location.reload()
        }
      }).catch(function() { window.showLoginApp() })
  }

  // if authRequired from dataset, trigger show method with args initComponent, authRequired = true
  if (el.dataset.authRequired === 'true') {
    if (new URLSearchParams(window.location.search).get('fp_fb') === 'true') {
      window.addEventListener('load', function() {
        FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            window.loginWithFBAccessToken(response.authResponse.accessToken)
          } else {
            window.showLoginApp()
          }
        })
      })
    } else {
      window.showLoginApp()
    }
  }
})
