<template>
  <Popup
    :dismissible="popupDismissible"
    @closePopup="closePopup"
    v-show="isPopupVisible"
    :overlayStyle="!isStaticPage">

    <template slot="header">
      <div class="popup-header__title">
        {{ headerTitle }}
      </div>
      <div class="popup-header__description">
        <span v-for="description in headerDescriptions" :key="description">{{ description }}</span>
      </div>
    </template>

    <div v-if="showSocialLogin">
      <social-login
        @swapComponent="swapComponent"
        :googleClientId="googleClientId"
      ></social-login>

      <!-- <div class="popup-body__or"><span class="popup-body__or-span">or</span><hr /></div> -->
    </div>

    <div class="popup-body">
      <span class="popup-body__create-account">New to Burpple?
        <a @click.prevent="createAccountTridentity">
          <u>Create an account</u>
        </a>
      </span>

      <span class="popup-body__info">
        You’ll be redirected to Tridentity’s login page.
        From Nov 2023 onwards, Burpple accounts will be powered by Tridentity for added security.
      </span>

    </div>

    <component
      :cityName="cityName"
      :countryName="countryName"
      :partner="partner"
      :is="currentComponent"
      :currentProperties="currentProperties"
      @swapComponent="swapComponent"
    ></component>

    <template slot="footer">
      <p style="font-size: 14px; font-weight:400;text-align: left;display: block;color: rgba(0, 0, 0, 0.6);" v-html="footerLink.title"></p>
    </template>
  </Popup>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Popup from '@/shared/layouts/popup.vue'
import Default from './components/default.vue'
import SignUp from './components/signup.vue'
import LogIn from './components/login.vue'
import SocialLogin from './components/social_login.vue'
import ResetPassword from './components/reset_password.vue'
import ResetPasswordEmailSent from './components/reset_password_email_sent.vue'

export default {
  props: {
    cityName: {
      type: String
    },
    countryName: {
      type: String
    },
    googleClientId: {
      type: String,
      required: true
    },
    partner: {
      type: String
    },
    isStaticPage: {
      type: Boolean
    },
    removeSocialLogin: {
      type: Boolean
    },
    initComponent: {
      type: String,
      required: true
    }
  },
  components: {
    Popup,
    Default,
    SignUp,
    LogIn,
    ResetPassword,
    ResetPasswordEmailSent,
    SocialLogin
  },
  data: function () {
    return ({
      currentComponent: null,
      currentProperties: null,
      headerTitle: '',
      footerLink: {
        to: '',
        title: ''
      },
      SocialLogin: true,
      popupDismissible: true,
      isPopupVisible: false,
      amplitudeEventProperties: {}
    })
  },
  computed: {
    showSocialLogin: function() {
      return this.SocialLogin && !this.removeSocialLogin
    }
  },
  methods: {
    swapComponent: function(component, userObject) {
      this.currentComponent = component
      this.currentProperties = userObject

      this.sendAmplitudeLogEvent(this.currentComponent, window.location.pathname, this.amplitudeEventProperties)

      switch (component) {
      case 'default':
        this.headerTitle = 'The better way to'
        this.headerDescriptions = ['Discover', 'Dine', 'Save']
        this.footerLink.to = 'log-in'
        this.footerLink.title = 'By continuing, you agree to the <a href="/terms#privacy-policy">Privacy Policy</a> and <a href="/terms#terms-of-use">Terms of Service</a> of Burpple.'
        this.SocialLogin = !this.currentProperties
        break
      case 'sign-up':
        this.headerTitle = 'Create your Burpple account'
        this.footerLink.to = 'log-in'
        this.footerLink.title = 'Already have a Burpple account? Log in here'
        this.SocialLogin = !this.currentProperties
        break
      case 'log-in':
        this.headerTitle = 'The better way to'
        this.headerDescriptions = ['Discover', 'Dine', 'Save']
        this.footerLink.to = 'sign-up'
        this.footerLink.title = 'By continuing, you agree to the <a href="/terms#privacy-policy">Privacy Policy</a> and <a href="/terms#terms-of-use">Terms of Service</a> of Burpple.'
        this.SocialLogin = !this.currentProperties
        break
      case 'reset-password':
        this.headerTitle = 'Reset Password'
        this.footerLink.to = 'sign-up'
        this.footerLink.title = 'Don’t have a Burpple account? Sign up here'
        this.SocialLogin = false
        break
      }
    },
    createAccountTridentity: function () {
      const TRIDENTITY_FE_URL = window.TRIDENTITY_FE_URL;
      const TRIDENTITY_APP_ID = window.TRIDENTITY_APP_ID;

      const TRIDENTITY_CREATE_ACCOUNT_PAGE = `${TRIDENTITY_FE_URL}/oauth/register/phone-input?app_id=${TRIDENTITY_APP_ID}`

      // Redirect to tridentity login page
      window.location.href = TRIDENTITY_CREATE_ACCOUNT_PAGE
    },
    closePopup() {
      Object.assign(this.$data, this.$options.data())
      this.isPopupVisible = false

      if (!this.isStaticPage) {
        enableBodyScroll(this.$el.firstElementChild)
      }
    },
    showPopup(component, amplitudeEventProperties, authRequired) {
      // component: default, log-in, sign-up
      // amplitudeEventProperties: {}
      // authRequired: boolean
      this.amplitudeEventProperties = amplitudeEventProperties
      this.swapComponent(component)
      this.isPopupVisible = true
      if (authRequired) {
        this.popupDismissible = false
      }

      if (!this.isStaticPage) {
        disableBodyScroll(this.$el.firstElementChild)
      }
    },
    sendAmplitudeLogEvent(component, pathname, eventProperties) {
      let name = ''

      switch (component) {
      case 'default':
        name = BURPPLE_AMPLITUDE.beyond_view_signup_signin_popup
        break
      case 'sign-up':
        if (pathname.includes('/beyond/')) {
          name = BURPPLE_AMPLITUDE.beyond_view_sign_up
        } else {
          name = BURPPLE_AMPLITUDE.view_sign_up
        }
        break
      case 'log-in':
        if (pathname.includes('/beyond/')) {
          name = BURPPLE_AMPLITUDE.beyond_view_sign_in
        } else {
          name = BURPPLE_AMPLITUDE.view_sign_in
        }
        break
      case 'reset-password':
        name = BURPPLE_AMPLITUDE.view_reset_password
        break
      }

      amplitudeLogEvent(name, Object.assign(eventProperties, {
        'type': this.isStaticPage ? 'page' : 'modal',
        'path': window.location.pathname
      }))
    }
  },
  created() {
    // listening showPopupLoginApp from popup_login_app.js
    this.$parent.$on('showPopupLoginApp', this.showPopup)

    // for static pages, /sign_in, /sign_up, /beyond/sign_in, /beyond/sign_up
    if (this.isStaticPage) {
      this.swapComponent(this.initComponent)
      this.isPopupVisible = true
      this.popupDismissible = false
    }
  }
}
</script>
