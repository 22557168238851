<template>
  <form>
    <form-input
      name="email"
      type="email"
      rules="required|email"
      v-model="user.email"
      labelName="Email Address"
      :labelImgSrc="require('@/assets/images/icon-email')"
    ></form-input>

    <button
      @click.prevent="onSubmit()"
      :disabled="isSubmitting"
      class="btn btn-block btn-beyond-green"
      :class="{ 'loading': isSubmitting }"
    >SEND RESET LINK</button>
  </form>
</template>

<script>
import FormInput from '@/shared/components/form_input'

export default {
  components: {
    FormInput
  },
  data: function() {
    return ({
      user: {
        email: ''
      },
      isSubmitting: false
    })
  },
  methods: {
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isSubmitting = true
          this.$http.post('/api/users/password', { user: this.user })
            .then(() => {
              this.$emit('swapComponent', 'reset-password-email-sent')
            })
            .catch(error => {
              if (error.response.status == 422 && error.response.data) {
                let dataObject = error.response.data
                for(var index in dataObject) {
                  this.errors.add({
                    field: index,
                    msg: dataObject[index][0]
                  })
                }
              }
              this.isSubmitting = false
            })
          return
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100%;
}
</style>
